import React, { useReducer } from "react";

export const data = [
  {
    partnerId: "Smartloc",
    affiliationCode: "ae24ff50-6446-11ea-a917-2f990b2efaf9",
    phoneNumber: "01 70 96 50 77",
  },
  {
    partnerId: "Bailfacile.fr",
    affiliationCode: "069fdb40-6830-11ea-bf77-6991428602b5",
    phoneNumber: "01 70 96 50 76",
  },
  {
    partnerId: "MaPlateformePro",
    affiliationCode: "72517030-71d3-11eb-a8b6-99d057ae2910",
    phoneNumber: "01 70 96 50 70",
  },
  {
    partnerId: "Maison.fr",
    affiliationCode: "ace99740-71d3-11eb-a8b6-99d057ae2910",
    phoneNumber: "01 70 96 50 85",
  },
  {
    partnerId: "GoogleAds",
    affiliationCode: "23ac7d10-7847-11eb-b359-11d0567f6bf9",
    phoneNumber: "01 70 96 50 97",
  },
  {
    partnerId: "FacebookAds",
    affiliationCode: "8d830f20-8570-11eb-9c7c-9bab4580ee38",
    phoneNumber: "01 70 95 26 26",
  },
  {
    partnerId: "LeBonCoin",
    affiliationCode: "007237e0-b6ea-11eb-8635-cf57e453eadd",
    phoneNumber: "01 70 96 50 63",
  },
  {
    partnerId: "MicrosoftAds",
    affiliationCode: "62afe8e0-d58e-11eb-990d-43aae9b9594e",
    phoneNumber: "01 70 95 26 03",
  },
  {
    partnerId: "Leggett",
    affiliationCode: "00c2e940-d591-11eb-a2a1-f9e428bc354b",
    phoneNumber: "01 70 95 26 02",
  },
  {
    partnerId: "Habitat & Humanism",
    affiliationCode: "4806aa30-db13-11eb-b2b3-058fba6231a6",
    phoneNumber: "01 70 95 26 01",
  },
];

function useQuery() {
  const isSSR = typeof window !== "undefined";
  return new URLSearchParams(isSSR ? window.location.search : null);
}

const initialState = {
  partnerId: "",
  phoneNumber: "01 70 82 17 82",
  affiliationCode: "",
  search: {
    postalCode: "",
    type: "",
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "set_app_context":
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
}

export const AppContext = React.createContext(initialState);

export const AppContextProvider = ({ children }) => {
  const affiliationCode = useQuery().get("affiliationCode");
  const partnerId = useQuery().get("partnerId");
  const [state, dispatch] = useReducer(
    reducer,
    initialState,
    (initialState) => {
      let appState = initialState;
      if (
        affiliationCode &&
        data.find((d) => d.affiliationCode === affiliationCode)
      ) {
        appState = {
          ...data.find((d) => d.affiliationCode === affiliationCode),
        };
        if (partnerId) {
          appState = { ...state, partnerId };
        }
      } else if (
        affiliationCode &&
        data.find((d) => d.affiliationCode === partnerId)
      ) {
        appState = {
          ...data.find((d) => d.affiliationCode === partnerId),
        };
      }
      return appState;
    },
  );

  const setAppContext = (appContext) => {
    dispatch({ type: "set_app_context", payload: appContext });
  };

  return (
    <AppContext.Provider value={{ state, actions: { setAppContext } }}>
      {children}
    </AppContext.Provider>
  );
};
